//==============================================================================
//                                 DEPENDENCIES
//==============================================================================

// ES6 polyfill for Object.assign()
var assign = require('object-assign');


function isObject (arg) {
    "use strict";
    return arg !== null && typeof arg === 'object';
}


//==============================================================================
//                         SingleEventChannel() - CONSTRUCTOR
//
//  USAGE:
//      
//      // get the singleton and see who's listening to some topic
//
//      1.  var abc = new SingleEventChannel().getInstance()
//          var subscribers = EventSingularity.prototype.abc.topics[topic]
//
//  NOTE:
//
//      - a singleton representing a shared communication channel
//
//      - allows EventSingularity to use the 'new' keyword and thus it can share
//        communication with other EventSingularity objects (new or extended) 
//        outside any AngularJS controller. It can also be used in a service 
//        Service
//
//==============================================================================

function SingleEventChannel () {
    "use strict";
    this.topics = {};
    this.subscriberUserId = -1;
}

SingleEventChannel.prototype = Object.create({}, {

    //==========================================================================
    //                                                                  instance
    // - guarentees this module is a singleton

    instance : {
        value        : null, 
        enumerable   : true, 
        configurable : false, 
        writable     : true 
    },

    //==========================================================================
    //                                                                    init()
    // - initializes the singleton instance, 
    //   returns its public API

    init : {
        value : function () {
            "use strict";

            var sec = new SingleEventChannel();
         
            return {         
                topics : sec.topics,
                subscriberUserId : sec.subscriberUserId
            };
        }, 
        enumerable   : false, 
        configurable : false, 
        writable     : false 
    },

    //==========================================================================
    //                                                             getInstance()
    // - inits the singleton instance if null, 
    //   otherwise returns a reference to it

    getInstance: {
        value : function () {
            "use strict";

            if ( ! SingleEventChannel.prototype.instance ) {
                    SingleEventChannel.prototype.instance = SingleEventChannel.prototype.init();
            }
     
            return SingleEventChannel.prototype.instance;            
        },
        enumerable   : true,
        configurable : false,
        writable     : false
    }

});
SingleEventChannel.prototype.constuctor = SingleEventChannel;


//==============================================================================
//                         EventSingularity() - CONSTRUCTOR
//
//  USAGE:
//
//      // two event singularities communicating with each other
//
//      1.  var a = new EventSingularity()  // or  a = EventSingularity() , ie ,
//          var b = new EventSingularity()  // the 'new' keyword is not needed
//          a.on('x', fn)
//          b.emit('x', data)
//
//      // a extends itself with EventSingularity's prototypal properties
//
//      2.  var a = {x: 0, y: 1, z: 1};
//          EventSingularity(a)
//          a.on('x', fn)
//
//  NOTE:
//
//      - objects create with the constructor, or extended by it, all share the
//        same SingleEventChannel Instance, ie , they all communicate on the same
//        channel
//
//==============================================================================

function EventSingularity (arg) {
    "use strict";

    if ( this instanceof EventSingularity ) {   // 1. above with 'new' keyword
        return this;
    } else if ( isObject(arg) ) {               // 2. above, arg gets extended
        arg = assign(arg, EventSingularity.prototype);
    } else {                                    // 1. above, NO  'new' keyword
        return new EventSingularity();
    }
}

EventSingularity.prototype = Object.create({}, {

    // event handler singleton, shared by any extended or new obj
    conductor : {
        value : new SingleEventChannel().getInstance(),
        enumerable   : true,
        configurable : false,
        writable     : false
    },

    //==========================================================================
    //                                                                    emit()
    // - notifies all topic subscribers
    //
    // @param {string} topic
    // @param {any}    args

    emit : {
        value : function (topic, args) {
            "use strict";

            if ( ! EventSingularity.prototype.conductor.topics[topic] ) {
                return false;
            }

            var subscribers = EventSingularity.prototype.conductor.topics[topic];
            var length      = subscribers ? subscribers.length : 0;

            while (length--) {
                subscribers[length].func(topic, args);
            }

            return this;
        }, 
        enumerable   : true, 
        configurable : false, 
        writable     : false 
    },

    //==========================================================================
    //                                                                      on()
    // - registers a callback function given some topic's emission
    //
    // @param {string}   topic
    // @param {function} func

    on : {
        value : function (topic, func) {
            "use strict";

            if ( ! EventSingularity.prototype.conductor.topics[topic] ) {
                EventSingularity.prototype.conductor.topics[topic] = [];
            }
     
            var token = ( ++EventSingularity.prototype.conductor.subscriberUserId ).toString();

            EventSingularity.prototype.conductor.topics[topic].push({
                token : token,
                func  : func
            });

            return token;
        }, 
        enumerable   : true, 
        configurable : false, 
        writable     : false
    },

    //==========================================================================
    //                                                                     off()
    // - unregisters the callback function associated with the token
    // @param {string} token

    off : {
        value : function( token ) {
            "use strict";

            for ( var t in EventSingularity.prototype.conductor.topics ) {

                if ( EventSingularity.prototype.conductor.topics[t] ) {

                    for ( var i = 0, j = EventSingularity.prototype.conductor.topics[t].length; i < j; ++i ) {

                        if ( EventSingularity.prototype.conductor.topics[t][i].token === token ) {
                            EventSingularity.prototype.conductor.topics[t].splice( i, 1 );
                            return token;
                        }
                    }
                }
            }

            return this;
        }, 
        enumerable   : true, 
        configurable : false, 
        writable     : false
    }
});
EventSingularity.prototype.constuctor = EventSingularity;

module.exports = EventSingularity;

//==============================================================================
//                                 example usage
//==============================================================================

/*

// c & d inherit from EventSingularity
var c =  new EventSingularity();
var d =  EventSingularity();

var a = {
    alpha : "alpha",
    beta  : "beta"
};

// a extends EventSingularity
EventSingularity(a);

// ------------------------------------ test 1 ---------------------------------

console.log("test 1 : a was extended properly");
console.log(a.hasOwnProperty("alpha") && a.hasOwnProperty("conductor"));

var tokena = a.on("hey buddy", function (topic, name) {
    console.log("object 2: Go Away " + name);
});


// ------------------------------------ test 2 ---------------------------------

console.log("test 2 : EventSingularity");

var tokenc = c.on("hey buddy", function (topic, name) {
    console.log("object 1: Hey " + name);
});

console.log('round 2.a. - 2 listeners');
d.emit("hey buddy", "Dave");

c.off(tokenc);

console.log('round 2.b. - 1 listeners');
d.emit("hey buddy", "Dave");

a.off(tokena);

console.log('round 2.c. - 0 listeners');
d.emit("hey buddy", "Dave");

*/