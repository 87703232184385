//A service to hold the dependency on the communication-singularity.js library

app.service('Mediator', ['$q', 'DeferredWithUpdate', function($q, DeferredWithUpdate){
	//Mediator is now a constructor
	var Mediator = require('./communication-singularity');
	
	//Mediator is now an instance that provides an interface with the singleton
	var theMediator = new Mediator();
	
	//Contains an object for each registered listener
	//Objects have an ID property that (hopefully) uniquely identifies the listener, and a token property which is passed into the Mediator.off function
	var listenerDictionary = [];
	
	function removeListener(listenerID){
		for(var listenerCounter = 0, listenerCount = listenerDictionary.length; listenerCounter < listenerCount; listenerCounter++){
			if(listenerDictionary[listenerCounter].ID === listenerID){
				theMediator.off(listenerDictionary[listenerCounter].token);
				listenerDictionary.splice(listenerCounter, 1);
				break;
			}
		}
	}
	
	function addListener(event, listenerID){
		
		removeListener(listenerID);
		
		var deferred = DeferredWithUpdate.defer();
		
		var token = theMediator.on(event, function(topic, data){
			return deferred.resolve(data);
		});
		
		listenerDictionary.push({ID: listenerID, token: token});
		
		return(deferred.promise);
	}
	
	function sendEmit(topic, data){
		theMediator.emit(topic, data);
	}
	
	this.emit = sendEmit;
	this.off = removeListener;
	this.on = addListener;
}]);